const getTranslatedToPlDurationFormat = (hours, minutes) => {
  const hoursInt = parseInt(hours, 10);
  const minutesInt = parseInt(minutes, 10);

  let translatedHours = null;
  switch (hoursInt) {
    case 0:
      break;
    case 1:
      translatedHours = `${hoursInt} godzinę`;
      break;
    default:
      translatedHours = `${hoursInt} godziny`;
      break;
  }

  const translatedMinutes = minutesInt !== 0 ? `${minutes} minut` : null;
  let result = '';
  if (translatedHours !== null) {
    result += translatedHours;
  }
  if (translatedHours !== null && translatedMinutes !== null) {
    return ` ${translatedHours} i ${translatedMinutes}`;
  }
  if (translatedHours === null && translatedMinutes !== null) {
    return translatedMinutes;
  }

  return result;
};

export const getFormattedDuration = (duration) => {
  const date = new Date(0);
  date.setSeconds(duration);

  const hours = date.toISOString().substring(11, 13);
  const minutes = date.toISOString().substring(14, 16);

  return getTranslatedToPlDurationFormat(hours, minutes);
};
