import React from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { NavLinkItem } from 'components/atoms/NavLinkItem/NavLinkItem';
import NavbarItemDataProvider from 'providers/NavbarItemDataProvider';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { colors } from 'utils/colors';

export const DropdownNavLinkItemList = () => (
  <StyledDropdownMenu>
    {NavbarItemDataProvider().map((navItemData) =>
      navItemData.name !== 'Logo' ? (
        <StyledNavItemDropdown key={navItemData.name}>
          <NavLinkItem
            href={navItemData.href}
            text={navItemData.name}
            color={colors.black}
            alt={navItemData.name}
          />
        </StyledNavItemDropdown>
      ) : (
        <span />
      ),
    )}
    <StyledButtonLinkDropdown
      href="https://booksy.com/pl-pl/109035_miedzy-nami-kobietami-salon-kosmetyczny_salon-kosmetyczny_22068_bydgoszcz"
      primary="true"
      round="true"
    >
      Zapisz się na zabieg
    </StyledButtonLinkDropdown>
  </StyledDropdownMenu>
);

const StyledDropdownMenu = styled.div`
  @keyframes slideDown {
    0% {
      transform: translateY(-8%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  display: none;
  position: absolute;
  height: 378px;
  right: 2rem;
  top: 60px;
  width: 300px;
  background-color: rgba(231, 109, 87, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 10px;
  animation: slideDown 0.5s ease-in-out;
  z-index: 9999;

  @media ${maxDeviceSize.tablet} {
    display: block;
  }

  @media ${maxDeviceSize.phone} {
    left: 2rem;
    width: unset;
  }
`;

const StyledNavItemDropdown = styled.li`
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButtonLinkDropdown = styled(ButtonLink)`
  width: 100%;
  display: flex;
  justify-content: center;
`;
