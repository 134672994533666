import { Heading } from 'components/atoms/Heading/Heading';
import React from 'react';
import MainLayout from 'templates/MainLayout';
import SectionLayout from 'templates/SectionLayout';
import styled from 'styled-components';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import TerminalImage from 'assets/images/miedzy-nami-kobietami-terminal-wizytowka.jpeg';
import { maxDeviceSize } from 'utils/DeviceSize';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import { colors } from 'utils/colors';

const About = () => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        O nas
      </Heading>
      <StyledAboutWrapper>
        <StyledAboutSectionWithImageWrapper>
          <StyledAboutParagraphContainer>
            <Paragraph>
              Odkryj urok i perfekcję w naszym wyjątkowym salonie kosmetycznym w sercu Bydgoszczy.
              Zapraszamy Cię do miejsca, gdzie tworzymy niezwykłe metamorfozy dla Twoich rzęs, brwi
              i skóry, podkreślając naturalne piękno i dodając pewności siebie.
            </Paragraph>
            <Paragraph>
              Oddaj się w ręce naszych specjalistów, którzy sprawią, że poczujesz się wyjątkowo i
              przekażą Ci niezbędne wskazówki dotyczące pielęgnacji twojej skóry, brwi oraz rzęs.
            </Paragraph>
            <Paragraph>
              Znajdziesz u nas zabiegi z zakresu stylizacji brwi i rzęs, zabiegów na twarz, szyje i
              dekolt, makijażu permanentnego oraz makijaży okolicznościowych.
            </Paragraph>
            <Paragraph>
              Salon specjalizuje się głównie w zakresie szeroko pojętej stylizacji i odbudowy brwi.
            </Paragraph>
            <Paragraph>Prowadzimy szkolenia kosmetyczne i warsztaty dla osób prywatnych.</Paragraph>
            <Paragraph>Serdecznie zapraszamy</Paragraph>
          </StyledAboutParagraphContainer>
          <BlurImage
            hash="LDF~U09w9EjrMctR?b%L_4xUo~t6"
            src={TerminalImage}
            alt="Wizytówka terminal salon"
            width="500"
            height="500"
            bgColor={colors.primary}
          />
        </StyledAboutSectionWithImageWrapper>
      </StyledAboutWrapper>
    </StyledSectionLayout>
  </MainLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;
  @media ${maxDeviceSize.tablet} {
    margin-bottom: 420px;
  }
`;

const StyledAboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 100px;
`;

const StyledAboutSectionWithImageWrapper = styled.div`
  display: flex;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    padding: 0px 2rem;
  }
`;

const StyledAboutParagraphContainer = styled.div`
  flex-direction: column;
  padding-right: 2rem;
`;

export default About;
