import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { TreatmentAfterBeforeGallery } from 'components/molecules/TreatmentAfterBeforeGallery/TreatmentAfterBeforeGallery';

export const TreatmentCard = ({
  open,
  name,
  imageAfter,
  imageBefore,
  hashImageAfter,
  hashImageBefore,
  description,
  onClose,
  referentionNote,
}) => {
  if (open === false) {
    return null;
  }

  const provideImages = () => {
    const images = [];

    images.push({
      hash: hashImageAfter,
      imageSrc: imageAfter,
      description: `${name} przed zabiegiem`,
    });
    images.push({
      hash: hashImageBefore,
      imageSrc: imageBefore,
      description: `${name} po zabiegu`,
    });

    return images;
  };

  return (
    <StyledTreatmentCardOverlay onClick={onClose}>
      <StyledTreatmentCardContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <StyledCloseButtonContainer onClick={onClose}>
          <AiOutlineClose />
        </StyledCloseButtonContainer>
        <StyledBlurImageContainer>
          <TreatmentAfterBeforeGallery photos={provideImages()} />
        </StyledBlurImageContainer>
        <StyledDescriptionContainer>
          <Paragraph as="h3">{name}</Paragraph>
          <StyledDescription>{description}</StyledDescription>
          {referentionNote && <StyledReferentionNote>{referentionNote}</StyledReferentionNote>}
        </StyledDescriptionContainer>
      </StyledTreatmentCardContainer>
    </StyledTreatmentCardOverlay>
  );
};

TreatmentCard.propTypes = {
  name: PropTypes.string.isRequired,
  imageAfter: PropTypes.string.isRequired,
  imageBefore: PropTypes.string,
  hashImageAfter: PropTypes.string.isRequired,
  hashImageBefore: PropTypes.string,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.element.isRequired,
  referentionNote: PropTypes.string,
};

TreatmentCard.defaultProps = {
  imageBefore: '',
  hashImageBefore: '',
  referentionNote: '',
};

const StyledTreatmentCardOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99999;
`;

const StyledTreatmentCardContainer = styled.div`
  display: flex;
  width: auto;
  max-width: 90%;
  max-height: 90%;
  background-color: ${colors.primary};

  -webkit-box-shadow: 16px 14px 26px 5px rgba(66, 68, 90, 1);
  -moz-box-shadow: 16px 14px 26px 5px rgba(66, 68, 90, 1);
  box-shadow: 16px 14px 26px 5px rgba(66, 68, 90, 1);
  border: 2px solid ${colors.ternary};

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledBlurImageContainer = styled.div`
  margin: auto;
  width: 450px;

  @media ${maxDeviceSize.tablet} {
    width: 250px;
  }
`;

const StyledDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;

  @media ${maxDeviceSize.tablet} {
    padding: 1rem;
    font-size: 0.7rem;
  }
`;

const StyledCloseButtonContainer = styled.button`
  width: 50px;
  height: 50px;
  font-size: 3rem;
  color: ${colors.lightBlack};
  background-color: ${colors.primary};
  border: none;
  cursor: pointer;
  &:hover {
    color: ${colors.secondary};
  }
`;

const StyledDescription = styled(Paragraph)`
  white-space: pre-line;
`;

const StyledReferentionNote = styled.div`
  margin-top: 1rem;
  font-size: 0.8rem;
  white-space: pre-line;
  :before {
    content: '*';
  }

  @media ${maxDeviceSize.tablet} {
    font-size: 0.6rem;
  }
`;
