import React from 'react';
import { Heading } from 'components/atoms/Heading/Heading';
import styled from 'styled-components';
import TreatmentsDataProvider from 'providers/TreatmentsDataProvider';
import PropTypes from 'prop-types';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Treatment } from 'components/atoms/Treatment/Treatment';
import { v4 } from 'uuid';

export const TreatmentsList = ({ title, category }) => (
  <StyledTreatmentWrapper>
    <StyledHeading s="h2" id={title}>
      {title}
    </StyledHeading>
    {TreatmentsDataProvider(category).map((treatment) => (
      <Treatment
        name={treatment.name}
        imageAfter={treatment.imageAfter}
        imageBefore={treatment.imageBefore}
        hashImageAfter={treatment.hashImageAfter}
        hashImageBefore={treatment.hashImageBefore}
        description={treatment.description}
        referentionNote={treatment.referentionNote}
        key={v4()}
      />
    ))}
  </StyledTreatmentWrapper>
);

TreatmentsList.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

const StyledHeading = styled(Heading)`
  @media ${maxDeviceSize.tablet} {
    text-align: center;
  }
`;

const StyledTreatmentWrapper = styled.div`
  scroll-behavior: smooth;
  margin-top: 2rem;
`;
