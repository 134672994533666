import React from 'react';
import ReviewsDataProvider from 'providers/ReviewsDataProvider';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Heading/Heading';
import { maxDeviceSize } from 'utils/DeviceSize';

import SectionLayout from 'templates/SectionLayout';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ReviewBox } from 'components/atoms/ReviewBox/ReviewBox';

const Reviews = () => {
  const reviews = ReviewsDataProvider();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1520, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <SectionLayout secondary>
      <StyledHeading secondary="true">Opinie</StyledHeading>
      <StyledReviewWrapper>
        <StyledReviewContainer responsive={responsive}>
          {reviews.map((review) => (
            <ReviewBox
              key={review.author}
              author={review.author}
              stars={review.stars}
              review={review.review}
              link={review.fullReviewLink}
            />
          ))}
        </StyledReviewContainer>
      </StyledReviewWrapper>
    </SectionLayout>
  );
};

const StyledHeading = styled(Heading)`
  padding: 0 2rem;
`;

const StyledReviewContainer = styled(Carousel)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledReviewWrapper = styled.div`
  @media ${maxDeviceSize.tablet} {
    width: 100%;
  }
`;

export default Reviews;
