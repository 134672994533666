import React from 'react';
import MainLayout from 'templates/MainLayout';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Heading/Heading';
import SectionLayout from 'templates/SectionLayout';
import { maxDeviceSize } from 'utils/DeviceSize';
import { GalleryComponent } from 'components/organisms/GalleryComponent/GalleryComponent';

const MainGallery = () => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        Galeria
      </Heading>
      <StyledImagesWrapper>
        <GalleryComponent />
      </StyledImagesWrapper>
    </StyledSectionLayout>
  </MainLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;
  @media ${maxDeviceSize.tablet} {
    margin-bottom: 420px;
  }
`;

const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default MainGallery;
