import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Blurhash } from 'react-blurhash';
import { motion } from 'framer-motion';
import { maxDeviceSize } from 'utils/DeviceSize';
import { colors } from 'utils/colors';

const BlurImage = ({ src, alt, hash, width, height, bgColor, style, withoutPadding }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <>
      {!imageLoaded && (
        <Blurhash
          hash={hash}
          width={Number(width)}
          height={Number(height)}
          resolutionX={32}
          resolutionY={32}
          punch={1}
          style={style}
        />
      )}
      {imageLoaded && (
        <StyledImageContainer bgColor={bgColor} withoutPadding={withoutPadding}>
          <StyledImage
            initial={{ opacity: 0 }}
            animate={{ opacity: imageLoaded ? 1 : 0 }}
            transition={{ opacity: { duration: 0.1 } }}
            src={src}
            loading="lazy"
            alt={alt}
            width={width}
            height={height}
            style={style}
          />
        </StyledImageContainer>
      )}
    </>
  );
};

BlurImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  style: PropTypes.element,
  withoutPadding: PropTypes.string,
};

BlurImage.defaultProps = {
  width: '400',
  height: '400',
  bgColor: colors.lightBlack,
  style: { border: '1px solid rgba(228, 183, 116, 0.74)' },
  withoutPadding: 'false',
};

const StyledImage = styled(motion.img)`
  width: ${({ width }) => (width === '400' ? '100%' : width)};
  height: ${({ height }) => (height === '400' ? '100%' : height)};
  display: block;
  border: 1px solid rgba(228, 183, 116, 0.74);
  border-radius: 100%;
  transition: ease-in-out 0.3s;
  box-shadow: -2px 0px 24px -9px rgba(0, 0, 0, 1);
  &:hover {
    transform: translateY(-2px);
    opacity: 0.9;
  }
  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledImageContainer = styled.div`
  padding: ${({ withoutPadding }) => (withoutPadding === 'true' ? '0' : '2rem')};
  background-color: ${({ bgColor }) => bgColor};

  @media ${maxDeviceSize.tablet} {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export default BlurImage;
