import React from 'react';
import { Heading } from 'components/atoms/Heading/Heading';
import SectionLayout from 'templates/SectionLayout';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { maxDeviceSize } from 'utils/DeviceSize';
import blob1 from 'assets/images/blobs/blob.svg';
import blob4 from 'assets/images/blobs/blob4.svg';
import { TreatmentCategory } from 'components/atoms/TreatmentCategory/TreatmentCategory';
import { categories } from 'utils/categories';
import MakeUpIcon from 'assets/images/makeup.png';
import MakeUpPermanentIcon from 'assets/images/makeup-permament.png';
import lashesAndEyebrowsIcon from 'assets/images/eyelash.png';
import faceNeckIcon from 'assets/images/faceNeck.png';

const Treatments = () => (
  <StyledSectionLayout>
    <Heading withBlob="true">Zabiegi</Heading>
    <StyledCategoriesWrapper>
      <TreatmentCategory
        blob={blob1}
        name={categories.lashesAndEyebrows.label}
        icon={lashesAndEyebrowsIcon}
      />
      <TreatmentCategory blob={blob4} name={categories.faceNeck.label} icon={faceNeckIcon} />
      <TreatmentCategory blob={blob1} name={categories.makeup.label} icon={MakeUpIcon} />
      <TreatmentCategory
        blob={blob4}
        name={categories.makeupPermanent.label}
        icon={MakeUpPermanentIcon}
      />
    </StyledCategoriesWrapper>
    <StyledParagraph>
      Salon kosmetyczny świadczy usługi w zakresie: - laminacji rzęs i brwi - stylizacji brwi henną
      pudrową lub farbą - makijaży permanentnych brwi - makijaży okolicznościowych - zabiegów na
      twarz, dekolt i dłonie - przedłużenia rzęs metodą 1:1.
    </StyledParagraph>
    <StyledButtonLinkWrapper>
      <ButtonLink to="/zabiegi">Zobacz więcej</ButtonLink>
    </StyledButtonLinkWrapper>
  </StyledSectionLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 2rem calc((100vw - 1300px) / 2);
  }
`;

const StyledCategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  font-size: 4rem;
  justify-items: center;
  color: ${colors.secondary};
`;

const StyledParagraph = styled(Paragraph)`
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
`;

const StyledButtonLinkWrapper = styled.div`
  padding: 15px 0 0 0;
`;

export default Treatments;
