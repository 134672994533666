import React from 'react';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Heading/Heading';
import SectionLayout from 'templates/SectionLayout';
import { PhotoGallery } from 'components/molecules/PhotoGallery/PhotoGallery';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import { maxDeviceSize } from 'utils/DeviceSize';

const Gallery = () => (
  <SectionLayout secondary>
    <StyledHeading secondary="true">Galeria</StyledHeading>
    <StyledImagesWrapper>
      <PhotoGallery main={true}/>
    </StyledImagesWrapper>
    <StyledButtonLinkWrapper>
      <ButtonLink secondary="true" to="/galeria">
        Zobacz więcej
      </ButtonLink>
    </StyledButtonLinkWrapper>
  </SectionLayout>
);

const StyledHeading = styled(Heading)`
  @media ${maxDeviceSize.tablet} {
    padding: 0 2rem;
  }
`;

const StyledImagesWrapper = styled.div`
  @media ${maxDeviceSize.tablet} {
    width: 100%;
  }
`;

const StyledButtonLinkWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 0 0 0;

  @media ${maxDeviceSize.tablet} {
    padding: 0 2rem;
  }
`;

export default Gallery;
