import React from 'react';
import MainLayout from 'templates/MainLayout';
import Contact from 'views/contact/Contact';

const ContactPage = () => (
  <MainLayout>
    <Contact />
  </MainLayout>
);

export default ContactPage;
