import React from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { NavLinkItem } from 'components/atoms/NavLinkItem/NavLinkItem';
import NavbarItemDataProvider from 'providers/NavbarItemDataProvider';
import LogoImage from 'assets/images/miedzy-nami-kobietami-logo-image.png';
import { colors } from 'utils/colors';
import { Link } from 'react-router-dom';

export const NavLinkItemList = () => (
  <StyledNavItemsWrapper>
    {NavbarItemDataProvider().map((navItemData) =>
      navItemData.name !== 'Logo' ? (
        <StyledNavItem key={navItemData.name}>
          <NavLinkItem href={navItemData.href} text={navItemData.name} alt={navItemData.name} />
        </StyledNavItem>
      ) : (
        <StyledNavLinkImageWrapper key={navItemData.name}>
          <StyledNavLinkImageContainer>
            <Link to="/">
              <StyledNavLinkImage src={LogoImage} alt="logo" />
            </Link>
          </StyledNavLinkImageContainer>
        </StyledNavLinkImageWrapper>
      ),
    )}
  </StyledNavItemsWrapper>
);

const StyledNavItemsWrapper = styled.ul`
  display: flex;
  gap: 2rem;

  @media ${maxDeviceSize.smallScreen} {
    gap: 1.5rem;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    gap: 1.2rem;
  }
`;

const StyledNavLinkImageContainer = styled.div`
  margin: auto;
  border: 6px solid ${colors.secondary};
  background-color: ${colors.primary};
  border-radius: 100%;
  width: 250px;
  height: 250px;

  @media ${maxDeviceSize.smallScreen} {
    width: 200px;
    height: 200px;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    width: 150px;
    height: 150px;
  }

  @media ${maxDeviceSize.tablet} {
    width: 150px;
    height: 150px;
  }
`;

const StyledNavLinkImageWrapper = styled.div`
  z-index: 300;
  padding: 1.5rem;
  background-color: ${colors.lightBlack};
  border-radius: 100%;
`;

const StyledNavLinkImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 0rem;
`;

const StyledNavItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1rem;

  @media ${maxDeviceSize.tablet} {
    display: none;
  }
`;
