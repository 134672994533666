import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/DeviceSize';

export const IconBox = ({ Icon, onClick, id }) => (
  <StyledIconContainer id={id} onClick={onClick}>
    <Icon />
  </StyledIconContainer>
);

IconBox.propTypes = {
  Icon: PropTypes.element.isRequired,
  onClick: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
};

const StyledIconContainer = styled.div`
  height: 4rem;
  width: 4rem;
  line-height: 4.5rem;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
  flex-direction: row;
  margin: 0.3rem;
  background-color: ${colors.black};
  color: ${colors.white};
  &:hover {
    color: ${colors.ternary};
  }

  @media ${maxDeviceSize.tablet} {
    height: 3rem;
    width: 3rem;
    line-height: 3.5rem;
    font-size: 1.5rem;
  }
`;
