import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';

import { SocialNavbar } from 'components/molecules/SocialNavbar/SocialNavbar';
import { NavLinkItemList } from 'components/molecules/NavLinkItemList/NavLinkItemList';
import { DropdownNavLinkItemList } from 'components/molecules/DropdownNavLinkItemList/DropdownNavLinkItemList';

import { colors } from 'utils/colors';

export const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <StyledNavbarContainer>
      <SocialNavbar />
      <StyledNavbarItemWrapper>
        <StyledNavbar>
          <NavLinkItemList />
          <StyledToogleButton onClick={() => setToggle(!toggle)}>
            {toggle ? <StyledFaXmark fill={colors.white} /> : <StyledFaBars fill={colors.white} />}
          </StyledToogleButton>
          {toggle && <DropdownNavLinkItemList />}
        </StyledNavbar>
      </StyledNavbarItemWrapper>
    </StyledNavbarContainer>
  );
};

const StyledNavbarContainer = styled.div`
  margin-top: 4rem;

  @media ${maxDeviceSize.tablet} {
    margin-bottom: 2rem;
    margin-top: 0;
  }
`;

const StyledFaBars = styled(FaBars)`
  color: black;
`;

const StyledFaXmark = styled(FaXmark)`
  color: black;
`;

const StyledNavbar = styled.div`
  width: 100%;
  height: 60px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${maxDeviceSize.tablet} {
    justify-content: space-around;
  }
`;

const StyledNavbarItemWrapper = styled.div`
  position: relative;
  padding: 3rem 2rem;
  background-color: ${colors.lightBlack};
  display: flex;

  @media ${maxDeviceSize.smallScreen} {
    padding: 2rem 4rem;
  }

  @media ${maxDeviceSize.tablet} {
    padding: 1rem;
  }
`;

const StyledToogleButton = styled.div`
  font-size: 1.5rem;
  cursor: pointer;
  display: none;

  @media ${maxDeviceSize.tablet} {
    display: block;
  }
`;
