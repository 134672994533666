import styled from 'styled-components';
import { colors } from 'utils/colors';

export const ButtonSubmit = styled.button`
  background-color: ${colors.ternary};
  padding: 0.5rem 1rem;
  color: ${colors.black};
  font-size: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: ease-in-out 0.3s;
  border-radius: 20px;
  letter-spacing: 0.1rem;
  display: inline-block;

  &:hover {
    transform: translateY(-2px);
    color: ${colors.white} !important;
  }
`;
