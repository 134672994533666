import React from 'react';
import MainLayout from 'templates/MainLayout';
import About from 'views/about/About';
import Contact from 'views/contact/Contact';
import Gallery from 'views/gallery/Gallery';
import Reviews from 'views/reviews/Reviews';
import Treatments from 'views/treatments/Treatments';

const IndexPage = () => (
  <MainLayout>
    <About />
    <Gallery />
    <Treatments />
    <Reviews />
    <Contact />
  </MainLayout>
);

export default IndexPage;
