import TreatmentsData from 'assets/treatmentsData.json';
import jsonToArrayDeserializer from 'serializers/jsonToArrayDeserializer';

const TreatmentsDataProvider = (category) => {
  const treatmets = jsonToArrayDeserializer(TreatmentsData);

  return treatmets.filter((treatment) => treatment.category === category);
};

export default TreatmentsDataProvider;
