import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

export const TreatmentCategory = ({ blob, linkTo, name, icon, cursor, big }) => (
  <Link to={linkTo} spy smooth offset={-70} duration={500}>
    <StyledCategoryWrapper blob={blob} cursor={cursor} big={big}>
      <StyledCategoryContainer>
        <StyledIcon src={icon} alt={name} />
        <StyledCategoryDescription big={big}>{name}</StyledCategoryDescription>
      </StyledCategoryContainer>
    </StyledCategoryWrapper>
  </Link>
);

TreatmentCategory.propTypes = {
  linkTo: PropTypes.string,
  name: PropTypes.string.isRequired,
  blob: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  cursor: PropTypes.string,
  big: PropTypes.bool,
};

TreatmentCategory.defaultProps = {
  linkTo: '#',
  cursor: 'auto',
  big: false,
};

const StyledCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: ${({ blob }) => `url(${blob}) right bottom no-repeat`};
  transition: all 0.3s ease-in-out;
  color: black;
  cursor: ${({ cursor }) => cursor};
  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }
  width: ${({ big }) => (big === true ? '600px' : '350px')};
  height: ${({ big }) => (big === true ? '600px' : '350px')};

  @media ${maxDeviceSize.smallScreen} {
    width: ${({ big }) => (big === true ? '500px' : '250px')};
    height: ${({ big }) => (big === true ? '500px' : '250px')};
    font-size: 1rem;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    width: ${({ big }) => (big === true ? '350px' : '150px')};
    height: ${({ big }) => (big === true ? '350px' : '150px')};
  }

  @media ${maxDeviceSize.phone} {
    width: 90px;
    height: 90px;
    font-size: 1.5rem;
  }
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCategoryDescription = styled.div`
  width: 150px;
  font-size: ${({ big }) => (big === true ? '2rem' : '1.5rem')};

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1.3rem;
  }

  @media ${maxDeviceSize.tablet} {
    display: none;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    display: none;
  }
`;

const StyledIcon = styled.img`
  width: 30%;
`;
