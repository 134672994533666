import React from 'react';
import blob1 from 'assets/images/blobs/blob.svg';
import blob4 from 'assets/images/blobs/blob4.svg';
import OnlineCourse from 'assets/images/online-learning.png';
import StationaryCourse from 'assets/images/presentation.png';
import styled from 'styled-components';
import { TrainingCategory } from 'components/atoms/TrainingCategory/TrainingCategory';
import { maxDeviceSize } from 'utils/DeviceSize';

const TrainingCategories = () => (
  <StyledCategoryContainer>
    <TrainingCategory
      blob={blob1}
      name="Szkolenia stacjonarne"
      linkTo="/szkolenia/szkolenia-stacjonarne"
      icon={StationaryCourse}
      cursor="pointer"
    />
    <TrainingCategory
      blob={blob4}
      name="Szkolenia online"
      linkTo="/szkolenia/szkolenia-online"
      icon={OnlineCourse}
      cursor="pointer"
    />
  </StyledCategoryContainer>
);

const StyledCategoryContainer = styled.div`
  display: flex;
  justify-content: center;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export default TrainingCategories;
