import React from 'react';
import MainLayout from 'templates/MainLayout';
import { Heading } from '@storybook/blocks';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import SectionLayout from 'templates/SectionLayout';
import { maxDeviceSize } from 'utils/DeviceSize';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { BiPhone } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

export const StacionaryTrainingContentWrapper = ({
  children,
  title,
  image,
  hashImage,
  description,
}) => (
  <MainLayout>
    <StyledSectionLayout>
      <StyledHeading withBlob="true" as="h1">
        {title}
      </StyledHeading>
      <StyledContentWrapper>
        <StyledBlurImageContainer>
          <BlurImage src={image} hash={hashImage} bgColor={colors.primary} alt={title} />
        </StyledBlurImageContainer>
        <StyledDescriptionContainer>
          <StyledDescription>{description}</StyledDescription>
          <StyledContactLinkWrapper>
            <StyledContactDescription>
              Masz pytania zadzwoń, napisz do mnie lub zostaw swój numer telefonu:
            </StyledContactDescription>
            <StyledContactLink
              href={process.env.REACT_APP_NUMBER_LINK}
              alt="Numer telefonu"
              textAfter={process.env.REACT_APP_NUMBER}
              aria-label="Numer telefonu"
            >
              <BiPhone /> {process.env.REACT_APP_NUMBER}
            </StyledContactLink>
            <StyledContactLink
              href={process.env.REACT_APP_EMAIL_LINK}
              alt="Adres e-mail"
              aria-label="Adres e-mail"
            >
              <CiMail /> {process.env.REACT_APP_EMAIL}
            </StyledContactLink>
          </StyledContactLinkWrapper>
          <StyledOrderSectionWrapper>{children}</StyledOrderSectionWrapper>
        </StyledDescriptionContainer>
      </StyledContentWrapper>
      <ToastContainer />
    </StyledSectionLayout>
  </MainLayout>
);

StacionaryTrainingContentWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  hashImage: PropTypes.string.isRequired,
  description: PropTypes.string,
};

StacionaryTrainingContentWrapper.defaultProps = {
  children: null,
  description: '',
};

const StyledHeading = styled(Heading)`
  margin-top: 1rem;
`;

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 450px;
`;

const StyledBlurImageContainer = styled.div`
  display: flex;
  justify-content: center;
  @media ${maxDeviceSize.tablet} {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

const StyledDescriptionContainer = styled.div`
  padding: 1rem;
`;

const StyledDescription = styled(Paragraph)`
  white-space: pre-line;
`;

const StyledContentWrapper = styled.div`
  display: flex;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
  }
`;

const StyledContactLinkWrapper = styled.div`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const StyledContactDescription = styled(Paragraph)`
  margin-bottom: 1rem;
`;

const StyledContactLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: none;
  color: ${colors.black};
  background-color: ${colors.ternary};
  padding: 1rem;
  margin-bottom: 7px;
  transition: ease-in-out 0.3s;
  justify-content: center;
  border-radius: 20px;
  letter-spacing: 0.1rem;

  @media ${maxDeviceSize.tablet} {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  @media ${maxDeviceSize.smallScreen} {
    font-size: 0.8rem;
  }

  :hover {
    color: ${colors.white};
    transform: translateY(-2px);
  }
`;

const StyledOrderSectionWrapper = styled.div``;
