import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { Link } from 'react-router-dom';
import { maxDeviceSize } from 'utils/DeviceSize';

export const NavLinkItem = ({ href, alt, text, color }) => (
  <StyledNavLinkItem to={href} alt={alt} color={color}>
    {text}
  </StyledNavLinkItem>
);

NavLinkItem.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

NavLinkItem.defaultProps = {
  color: colors.white,
};

const StyledNavLinkItem = styled(Link)`
  text-decoration: none;
  color: ${({ color }) => color};
  font-size: 1.2rem;
  letter-spacing: 1px;
  text-align: center;

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1rem;
  }

  :hover {
    color: ${colors.ternary};
  }
`;
