import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import galleryProvider from 'providers/GalleryProvider';
import { colors } from 'utils/colors';
import styled from 'styled-components';
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose } from 'react-icons/ai';
import { IconBox } from 'components/atoms/IconBox/IconBox';
import BlurImage from 'components/molecules/BlurImage/BlurImage';

export const GalleryComponent = () => {
  const gallery = galleryProvider();
  const [fullScreenRealization, setFullScreenRealization] = useState({
    src: '',
    desc: '',
    index: 0,
  });
  const images = galleryProvider();
  const viewFullScreenRealization = (src, desc, index) => {
    setFullScreenRealization({ src, desc, index });
  };

  const realizationAction = (action) => {
    let { index } = fullScreenRealization;

    if (action === 'next-realization') {
      if (index + 1 === images.length) {
        index = 0;
      }
      setFullScreenRealization({
        src: images[index + 1].imageSrc,
        desc: images[index + 1].description,
        index: index + 1,
      });
    }
    if (action === 'previous-realization') {
      if (index === 0) {
        index = 9;
      }
      setFullScreenRealization({
        src: images[index - 1].imageSrc,
        desc: images[index - 1].description,
        index: index - 1,
      });
    }
    if (action === 'close-fullscreen') {
      setFullScreenRealization({
        src: '',
        desc: '',
        index: 0,
      });
    }
  };

  return (
    <>
      {fullScreenRealization.src && (
        <StyledFullScreenRealizationContainer>
          <StyledCloseFullScreenContainer>
            <IconBox
              Icon={AiOutlineClose}
              onClick={() => realizationAction('close-fullscreen')}
              id="close-preview-button"
            />
          </StyledCloseFullScreenContainer>
          <IconBox
            Icon={AiOutlineArrowLeft}
            onClick={() => realizationAction('previous-realization')}
            id="next-slide-button"
          />
          <StyledFullScreenRealizationImage
            src={fullScreenRealization.src}
            alt={fullScreenRealization.desc}
          />
          <IconBox
            Icon={AiOutlineArrowRight}
            onClick={() => realizationAction('next-realization')}
            id="previous-slide-button"
          />
        </StyledFullScreenRealizationContainer>
      )}
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 1000: 2, 1530: 3 }}
        style={{ padding: '10px' }}
      >
        <Masonry gutter="20px">
          {gallery.map((realization, key) => (
            <StyledImageButtonWrapper
              type="button"
              /* prettier-ignore */
              /* eslint-disable-next-line */
              onClick={() => viewFullScreenRealization(realization.imageSrc, realization.description, key)}
              key={realization.hash}
            >
              <BlurImage
                src={realization.imageSrc}
                alt={realization.description}
                hash={realization.hash}
                bgColor={colors.primary}
              />
            </StyledImageButtonWrapper>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};

const StyledCloseFullScreenContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledFullScreenRealizationContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.black};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 99999;
`;

const StyledFullScreenRealizationImage = styled.img`
  width: auto;
  max-width: 90%;
  max-height: 90%;
  border: 1px solid rgba(228, 183, 116, 0.74);
  border-radius: 100%;
  box-shadow: -2px 0px 24px -9px rgba(255, 255, 255, 1);
`;

const StyledImageButtonWrapper = styled.button`
  cursor: pointer;
  border: none;
  &:hover {
    opacity: 0.8;
    transition: 0.5 ease-in-out;
  }
`;
