import React from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Heading } from 'components/atoms/Heading/Heading';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import SectionLayout from 'templates/SectionLayout';
import GoogleMapsImage from 'assets/images/między-nami-kobietami-mapa-google.png';
import { BiPhone } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';

import { colors } from 'utils/colors';

const Contact = () => (
  <StyledSectionLayout>
    <Heading withBlob="true">Kontakt</Heading>
    <StyledContactWrapper>
      <StyledMapSectionWrapper>
        <StyledMapParagraph> Naciśnij mapkę, aby nawigować do salonu:</StyledMapParagraph>

        <StyledImageLinkWrapper href={process.env.REACT_APP_GOOGLE_MAP_LINK} target="_blank">
          <img src={GoogleMapsImage} alt="miedzy nami kobietami odnośnik do mapy google" />
        </StyledImageLinkWrapper>
      </StyledMapSectionWrapper>
      <StyledParagraphWrapper>
        <Paragraph>Skontaktuj się ze mną i umów się na zabieg.</Paragraph>
        <StyledContactLinkWrapper>
          <StyledContactLink
            href={process.env.REACT_APP_NUMBER_LINK}
            alt="Numer telefonu"
            textAfter={process.env.REACT_APP_NUMBER}
            aria-label="Numer telefonu"
          >
            <BiPhone /> {process.env.REACT_APP_NUMBER}
          </StyledContactLink>
          <StyledContactLink
            href={process.env.REACT_APP_EMAIL_LINK}
            alt="Adres e-mail"
            aria-label="Adres e-mail"
          >
            <CiMail /> {process.env.REACT_APP_EMAIL}
          </StyledContactLink>
        </StyledContactLinkWrapper>
      </StyledParagraphWrapper>
    </StyledContactWrapper>
  </StyledSectionLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 460px;
    padding: 2rem calc((100vw - 1300px) / 2);
  }
`;

const StyledContactLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1rem;
  }

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }
`;

const StyledParagraphWrapper = styled.div`
  padding: 0 50px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${maxDeviceSize.tablet} {
    padding: 0 2rem;
  }
`;

const StyledImageLinkWrapper = styled.a`
  > img {
    border: 1px solid rgba(228, 183, 116, 0.74);
    border-radius: 20px;
    transition: all 0.3s ease-in-out;

    @media ${maxDeviceSize.tablet} {
      width: 80%;
      height: 80%;
    }

    &:hover {
      transform: translateY(-2px);
      opacity: 0.7;
    }
  }

  @media ${maxDeviceSize.tablet} {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
`;

const StyledMapSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const StyledMapParagraph = styled(Paragraph)`
  text-align: center;
  font-size: 12px;
  opacity: 0.9;
`;

const StyledContactLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: none;
  color: ${colors.black};
  background-color: ${colors.ternary};
  padding: 1rem;
  margin-bottom: 7px;
  transition: ease-in-out 0.3s;
  justify-content: center;
  border-radius: 20px;
  letter-spacing: 0.1rem;

  @media ${maxDeviceSize.tablet} {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  @media ${maxDeviceSize.smallScreen} {
    font-size: 0.8rem;
  }

  :hover {
    color: ${colors.white};
    transform: translateY(-2px);
  }
`;

export default Contact;
