import React from 'react';
import styled from 'styled-components';
import { SocialLink } from 'components/atoms/SocialLink/SocialLink';
import { BiLogoFacebookCircle, BiLogoInstagram, BiPhone } from 'react-icons/bi';
import { ContactLink } from 'components/atoms/ContactLink/ContactLink';
import { Button } from 'components/atoms/Button/Button';
import { maxDeviceSize } from 'utils/DeviceSize';

export const SocialNavbar = () => (
  <StyledNavbarSocialWrapper>
    <StyledSocialNavbarSocialLinkContainer>
      <div>
        <SocialLink
          href={process.env.REACT_APP_FACEBOOK_LINK}
          alt="między nami kobietami facebook"
          Icon={BiLogoFacebookCircle}
          ariaLabel="Facebook"
        />
        <SocialLink
          href={process.env.REACT_APP_INSTAGRAM_LINK}
          alt="między nami kobietami instagram"
          Icon={BiLogoInstagram}
          ariaLabel="Instagram"
        />
      </div>
      <StyledSocialNavbarContactContainer>
        <ContactLink
          href={process.env.REACT_APP_NUMBER_LINK}
          alt="Numer telefonu"
          Icon={BiPhone}
          textAfter={process.env.REACT_APP_NUMBER}
          ariaLabel="Numer telefonu"
        />
      </StyledSocialNavbarContactContainer>
    </StyledSocialNavbarSocialLinkContainer>
    <StyledButton href={process.env.REACT_APP_BOOKSY_LINK} target="_blank">
      Zapisz się na zabieg
    </StyledButton>
  </StyledNavbarSocialWrapper>
);

const StyledNavbarSocialWrapper = styled.div`
  margin-top: 7px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  z-index: 9999;

  @media ${maxDeviceSize.tablet} {
    margin-bottom: 3rem;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  @media ${maxDeviceSize.tablet} {
    display: none;
  }
  margin-left: 400px;
`;

const StyledSocialNavbarContactContainer = styled.div``;

const StyledSocialNavbarSocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
