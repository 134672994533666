import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from 'pages/IndexPage';
import Contact from 'pages/Contact';
import GiftCard from 'pages/GiftCard';
import Treatments from 'pages/Treatments';
import Training from 'pages/Training/Training';
import Pricing from 'pages/Pricing';
import About from 'pages/About';
import NotFound from 'pages/NotFound';
import MainGallery from 'pages/MainGallery';
import StationaryTraining from 'pages/Training/StationaryTraining';
import OnlineTraining from 'pages/Training/OnlineTraining';
import StacionaryTrainingContentPage from './Training/StacionaryTrainingContentPage';

const Pages = () => (
  <AnimatePresence mode="wait">
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="/o-nas" element={<About />} />
      <Route path="/zabiegi" element={<Treatments />} />
      <Route path="/galeria" element={<MainGallery />} />
      <Route path="/cennik" element={<Pricing />} />
      <Route path="/szkolenia" element={<Training />} />
      <Route path="/szkolenia/szkolenia-stacjonarne" element={<StationaryTraining />} />
      <Route
        path="/szkolenia/szkolenia-stacjonarne/:id"
        element={<StacionaryTrainingContentPage />}
      />
      <Route path="/szkolenia/szkolenia-online" element={<OnlineTraining />} />
      <Route path="/bon-upominkowy" element={<GiftCard />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </AnimatePresence>
);
export default Pages;
