import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import GalleryProvider from 'providers/GalleryProvider';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import PropTypes from 'prop-types';

export const PhotoGallery = ({ main }) => {
  const gallery = GalleryProvider(main);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1520, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <StyledGalleryContainer responsive={responsive}>
      {gallery.map((imageData) => (
        <BlurImage
          key={imageData.hash}
          src={imageData.imageSrc}
          alt={imageData.description}
          hash={imageData.hash}
          width="399"
          height="399"
        />
      ))}
    </StyledGalleryContainer>
  );
};

PhotoGallery.propTypes = {
  main: PropTypes.bool,
};

PhotoGallery.defaultProps = {
  main: false,
};

const StyledGalleryContainer = styled(Carousel)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
  }
`;
