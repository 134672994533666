import { Heading } from 'components/atoms/Heading/Heading';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import React from 'react';
import MainLayout from 'templates/MainLayout';
import SectionLayout from 'templates/SectionLayout';
import styled from 'styled-components';

const OnlineTraining = () => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        Szkolenia online
      </Heading>
      <StyledParagraph>Już niebawem :)</StyledParagraph>
    </StyledSectionLayout>
  </MainLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 450px;
`;

const StyledParagraph = styled(Paragraph)`
  padding: 1rem;
`;

export default OnlineTraining;
