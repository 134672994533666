import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/DeviceSize';

export const SocialLink = ({ href, alt, ariaLabel, Icon, alternativeColor }) => (
  <StyledSocialLink
    href={href}
    alt={alt}
    target="_blank"
    aria-label={ariaLabel}
    alternativeColor={alternativeColor}
  >
    <Icon />
  </StyledSocialLink>
);

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  alternativeColor: PropTypes.bool,
};

SocialLink.defaultProps = {
  alternativeColor: false,
};

const StyledSocialLink = styled.a`
  font-size: 28px;
  text-decoration: none;
  color: ${({ alternativeColor }) => (alternativeColor === true ? colors.primary : colors.black)};

  @media ${maxDeviceSize.phone} {
    svg {
      width: 48px;
      height: 48px;
    }
  }

  :hover {
    color: ${colors.secondary};
  }
`;
