import React, { useState } from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { colors } from 'utils/colors';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { ButtonSubmit } from 'components/atoms/ButtonSubmit/ButtonSubmit';
import 'react-toastify/dist/ReactToastify.css';
import { OrderCardMailTemplate } from 'templates/Mail/OrderCardTemplate';
import { render } from '@react-email/render';
import { validatePhoneNumber } from 'services/InputValidator';
import { GiftCardWrapper } from 'components/organisms/GiftCardWrapper/GiftCardWrapper';
import { Loading } from 'components/atoms/Loading/Loading';
import { OrderCardMailUserTemplate } from 'templates/Mail/OrderCardUserTemplate';

const GiftCard = () => {
  const [data, setData] = useState({
    price: '',
    email: '',
    phoneNumber: '',
    comment: '',
  });
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleData = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(data.phoneNumber) === false) {
      setError('Niepoprawny numer telefonu.');
      return;
    }
    setLoading(true);

    const htmlTemplate = render(
      <OrderCardMailTemplate
        price={data.price}
        phoneNumber={data.phoneNumber}
        mail={data.email}
        comment={data.comment}
      />,
      {
        pretty: true,
      },
    );
    const htmlUserTemplate = render(
      <OrderCardMailUserTemplate price={data.price} mail={data.email} />,
      {
        pretty: true,
      },
    );
    Axios.post(
      `${process.env.REACT_APP_SERVER_URL}/order-card?token=${process.env.REACT_APP_MNK_TOKEN}`,
      {
        subject: 'Zamówienie bonu upominkowego',
        html: htmlTemplate,
      },
    )
      .then(() => {})
      .catch(() => {
        toast.error(
          'Wysłanie potwierdzenia wysłania maila do Między nami kobietami nie powiodło się.',
          {
            position: 'top-right',
            autoClose: 5000,
            theme: 'colored',
          },
        );
      });

    Axios.post(
      `${process.env.REACT_APP_SERVER_URL}/order-card?token=${process.env.REACT_APP_MNK_TOKEN}`,
      {
        to: data.email,
        subject: 'Zamówienie bonu upominkowego',
        html: htmlUserTemplate,
      },
    )
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch(() => {
        toast.error('Zamówinie bonu upominkowego nie powiodło się', {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  if (loading) {
    return (
      <GiftCardWrapper>
        <Loading message="Dziękujemy za złożenie zamówienia, wysyłamy właśnie wiadomość." />
      </GiftCardWrapper>
    );
  }
  if (sent) {
    toast.success(
      'Karta podarunkowa została zamówiona, szczegóły zostaną wysłane na adres e-mail',
      {
        position: 'top-right',
        autoClose: 5000,
        theme: 'colored',
      },
    );
    return (
      <GiftCardWrapper>
        <Paragraph>
          Dziękujemy karta podarunkowa została zamówiona, szczegóły zostaną wysłane na adres e-mail.
        </Paragraph>
      </GiftCardWrapper>
    );
  }

  return (
    <GiftCardWrapper>
      <StyledOrderForm onSubmit={(e) => onSubmit(e)}>
        <label htmlFor="price"> Wpisz kwotę: </label>
        <StyledOrderInput
          type="number"
          id="price"
          required
          name="price"
          min="100"
          step="1"
          placeholder="Kwota bonu upominkowego"
          onChange={(e) => handleData(e)}
          value={data.price}
        />
        <label htmlFor="email"> E-mail: </label>
        <StyledOrderInput
          type="email"
          id="email"
          name="email"
          required
          placeholder="Twój e-mail"
          onChange={(e) => handleData(e)}
          value={data.email}
        />
        <label htmlFor="phoneNumber"> Numer telefonu: </label>
        <StyledOrderInput
          type="text"
          id="phoneNumber"
          required
          name="phoneNumber"
          placeholder="Numer telefonu"
          onChange={(e) => handleData(e)}
          onEnded={() => validatePhoneNumber(data.phoneNumber)}
          value={data.phoneNumber}
          title="Pole numer telefonu przyjmuje wartość w zakresie 9 cyfr np. 123456789"
        />
        {error && <StyledErrorContainer>Niepoprawny numer telefonu</StyledErrorContainer>}
        <label htmlFor="comment"> Uwagi: </label>
        <StyledOrderTextArea
          id="comment"
          name="comment"
          rows="4"
          cols="70"
          maxLength="200"
          placeholder="Np. imię i nazwisko osoby obdarowanej + dodatkowe informacje"
          onChange={(e) => handleData(e)}
          value={data.comment}
        />
        <StyledOrderSubmitButton type="submit">Zamów</StyledOrderSubmitButton>
      </StyledOrderForm>
    </GiftCardWrapper>
  );
};

const StyledOrderForm = styled.form`
  padding: 3rem;
  display: flex;
  flex-direction: column;

  @media ${maxDeviceSize.tablet} {
    padding: 1rem 0 !important;
  }
`;

const StyledOrderInput = styled.input`
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;

  &:focus {
    outline: none !important;
    border: 2px solid ${colors.secondary};
    box-shadow: 0 0 10px #719ece;
  }
`;

const StyledOrderTextArea = styled.textarea`
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  resize: none;

  &:focus {
    outline: none !important;
    border: 2px solid ${colors.secondary};
    box-shadow: 0 0 10px #719ece;
  }
`;

const StyledOrderSubmitButton = styled(ButtonSubmit)`
  padding: 12px 20px;
  margin: 8px 0;
  background-color: ${colors.lightBlack};
  outline: none !important;
  border: 1px solid #ccc;
  color: white;
  font-size: 1rem;
  text-align: center;

  &:hover {
    color: ${colors.ternary};
  }
`;

const StyledErrorContainer = styled.div`
  color: white;
  font-size: 0.8rem;
  background-color: rgba(255, 0, 0, 0.8);
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid red;
`;

export default GiftCard;
