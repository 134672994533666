import { Heading } from 'components/atoms/Heading/Heading';
import { TrainingCard } from 'components/molecules/TrainingCard/TrainingCard';
import TrainingCardDataProvider from 'providers/TrainingCardDataProvider';
import React from 'react';
import styled from 'styled-components';
import MainLayout from 'templates/MainLayout';
import SectionLayout from 'templates/SectionLayout';
import { maxDeviceSize } from 'utils/DeviceSize';
import { v4 } from 'uuid';

const StationaryTraining = () => {
  const trainings = TrainingCardDataProvider();
  return (
    <MainLayout>
      <StyledSectionLayout>
        <Heading withBlob="true" as="h1">
          Szkolenia stacjonarne
        </Heading>
        <StyledTrainingCardContainer>
          {trainings.map((training) => (
            <TrainingCard
              key={v4()}
              title={training.title}
              image={training.imageSrc}
              hashImage={training.hash}
              cardPage={training.cardPage}
              description={training.description}
            />
          ))}
        </StyledTrainingCardContainer>
      </StyledSectionLayout>
    </MainLayout>
  );
};

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 450px;
`;

const StyledTrainingCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
  }
`;

export default StationaryTraining;
