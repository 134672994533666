import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { getFormattedDuration } from 'services/TimeManager';
import PropTypes from 'prop-types';
import { maxDeviceSize } from 'utils/DeviceSize';

export const BasicTreatment = ({ name, price, duration }) => (
  <StyledTreatmentContainer>
    <Paragraph as="h3">{name}</Paragraph>
    <Paragraph>kwota: {price} zł</Paragraph>
    {duration && <Paragraph>czas trwania: {getFormattedDuration(duration)}</Paragraph>}
  </StyledTreatmentContainer>
);

BasicTreatment.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
};

const StyledTreatmentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }

  @media ${maxDeviceSize.tablet} {
    width: 80%;
    margin: auto;
  }
`;
