import React from 'react';
import { Heading } from 'components/atoms/Heading/Heading';
import styled from 'styled-components';
import PricingDataProvider from 'providers/PricingDataProvider';
import PropTypes from 'prop-types';
import { maxDeviceSize } from 'utils/DeviceSize';
import { v4 } from 'uuid';
import { BasicTreatment } from 'components/atoms/BasicTreatment/BasicTreatment';

export const BasicTreatmentsList = ({ title, category, withDuration }) => (
  <StyledTreatmentWrapper>
    <StyledHeading s="h2" id={title}>
      {title}
    </StyledHeading>
    {PricingDataProvider(category).map((treatment) => (
      <BasicTreatment
        name={treatment.name}
        price={treatment.price}
        duration={withDuration === 'true' ? treatment.duration : null}
        key={v4()}
      />
    ))}
  </StyledTreatmentWrapper>
);

BasicTreatmentsList.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  withDuration: PropTypes.string,
};

BasicTreatmentsList.defaultProps = {
  withDuration: 'true',
};

const StyledHeading = styled(Heading)`
  @media ${maxDeviceSize.tablet} {
    text-align: center;
  }
`;

const StyledTreatmentWrapper = styled.div`
  scroll-behavior: smooth;
  margin-top: 2rem;
`;
