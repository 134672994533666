import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const TrainingCategory = ({ blob, linkTo, name, icon }) => (
  <Link to={linkTo} style={{ textDecoration: 'none' }}>
    <StyledCategoryWrapper blob={blob}>
      <StyledCategoryContainer>
        <StyledIcon src={icon} alt={name} />
        <StyledCategoryDescription>{name}</StyledCategoryDescription>
      </StyledCategoryContainer>
    </StyledCategoryWrapper>
  </Link>
);

TrainingCategory.propTypes = {
  linkTo: PropTypes.string,
  name: PropTypes.string.isRequired,
  blob: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

TrainingCategory.defaultProps = {
  linkTo: '#',
};

const StyledCategoryWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: ${({ blob }) => `url(${blob}) right bottom no-repeat`};
  transition: all 0.3s ease-in-out;
  color: black;
  cursor: ${({ cursor }) => cursor};
  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }
  width: 600px;
  height: 600px;

  @media ${maxDeviceSize.smallScreen} {
    width: 500px;
    height: 500px;
    font-size: 1rem;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    width: 400px;
    height: 400px;
  }

  @media ${maxDeviceSize.phone} {
    width: 300px;
    height: 300px;
    font-size: 1.5rem;
  }
`;

const StyledCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCategoryDescription = styled.div`
  width: 150px;
  text-decoration: none;
  font-size: 2rem;

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1.3rem;
  }
`;

const StyledIcon = styled.img`
  width: 30%;
`;
