import React from 'react';
import MainLayout from 'templates/MainLayout';
import { Heading } from 'components/atoms/Heading/Heading';
import SectionLayout from 'templates/SectionLayout';
import styled from 'styled-components';
import { TreatmentsComponent } from 'components/organisms/TreatmentsComponent/TreatmentsComponent';
import { maxDeviceSize } from 'utils/DeviceSize';
import ScrollToTop from 'react-scroll-to-top';
import { colors } from 'utils/colors';

const Treatments = () => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        Zabiegi
      </Heading>
      <TreatmentsComponent />
      <ScrollToTop smooth color={colors.black} style={{ backgroundColor: colors.secondary }} />
    </StyledSectionLayout>
  </MainLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;
  @media ${maxDeviceSize.tablet} {
    margin-bottom: 420px;
  }
`;

export default Treatments;
