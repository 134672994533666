import { createGlobalStyle } from 'styled-components';
import { colors } from 'utils/colors';

export const GlobalStyle = createGlobalStyle`
    * {
        font-family: 'Montserrat', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html {
        position: relative;
        min-height: 100%;
        overflow-x: hidden;
    }
    body {
        background-color: ${colors.primary};
    }
`;
