import { Heading } from 'components/atoms/Heading/Heading';
import React from 'react';
import MainLayout from 'templates/MainLayout';
import SectionLayout from 'templates/SectionLayout';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import TrainingCategories from 'components/molecules/TrainingCategories/TrainingCategories';

const Training = () => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        Zapisz się na szkolenie
      </Heading>
      <TrainingCategories big />
    </StyledSectionLayout>
  </MainLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;
  @media ${maxDeviceSize.tablet} {
    margin-bottom: 420px;
  }
`;

export default Training;
