import React from 'react';
import styled from 'styled-components';
import { categories } from 'utils/categories';
import { maxDeviceSize } from 'utils/DeviceSize';
import { TreatmentCategory } from 'components/atoms/TreatmentCategory/TreatmentCategory';
import blob1 from 'assets/images/blobs/blob.svg';
import blob4 from 'assets/images/blobs/blob4.svg';
import { TreatmentsList } from 'components/molecules/TreatmentsList/TreatmentsList';
import { colors } from 'utils/colors';
import MakeUpIcon from 'assets/images/makeup.png';
import MakeUpPermanentIcon from 'assets/images/makeup-permament.png';
import lashesAndEyebrowsIcon from 'assets/images/eyelash.png';
import faceNeckIcon from 'assets/images/faceNeck.png';

export const TreatmentsComponent = () => (
  <>
    <StyledCategoriesWrapper>
      <StyledCategoryWrapper>
        <TreatmentCategory
          blob={blob1}
          name={categories.lashesAndEyebrows.label}
          linkTo={categories.lashesAndEyebrows.label}
          icon={lashesAndEyebrowsIcon}
          cursor="pointer"
        />
        <TreatmentCategory
          blob={blob4}
          name={categories.faceNeck.label}
          linkTo={categories.faceNeck.label}
          icon={faceNeckIcon}
          cursor="pointer"
        />
        <TreatmentCategory
          blob={blob1}
          name={categories.makeup.label}
          linkTo={categories.makeup.label}
          icon={MakeUpIcon}
          cursor="pointer"
        />
        <TreatmentCategory
          blob={blob1}
          name={categories.makeupPermanent.label}
          linkTo={categories.makeupPermanent.label}
          icon={MakeUpPermanentIcon}
          cursor="pointer"
        />
      </StyledCategoryWrapper>
    </StyledCategoriesWrapper>
    <TreatmentsList
      category={categories.lashesAndEyebrows.name}
      title={categories.lashesAndEyebrows.label}
    />
    <TreatmentsList category={categories.faceNeck.name} title={categories.faceNeck.label} />
    <TreatmentsList category={categories.makeup.name} title={categories.makeup.label} />
    <TreatmentsList
      category={categories.makeupPermanent.name}
      title={categories.makeupPermanent.label}
    />
  </>
);

const StyledCategoryWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  @media ${maxDeviceSize.tablet} {
    justify-content: center;
  }
`;

const StyledCategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  font-size: 4rem;
  justify-items: center;
  color: ${colors.secondary};

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: none;
  }
`;
