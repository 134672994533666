import {
  Body,
  Container,
  Heading,
  Head,
  Hr,
  Html,
  Img,
  Preview,
  Text,
} from '@react-email/components';
import PropTypes from 'prop-types';
import * as React from 'react';
import { formatPriceToPln } from 'services/FormatterService';
import styled from 'styled-components';

export const OrderCardMailUserTemplate = ({ mail, price }) => (
  <Html>
    <Head />
    <Preview>Między nami kobietami</Preview>
    <StyledBody>
      <StyledContainer>
        <StyledLogo
          src="https://d375139ucebi94.cloudfront.net/region2/pl/109035/logo/4d044822a193410e883a4746af34b7-miedzy-nami-kobietami-salon-ko-logo-45ce82dc499049ce948ba6cbc3ad1a-booksy.jpeg"
          width="170"
          height="50"
          alt="Między-nami-kobietami"
        />
        <StyledParagraph>Cześć {mail}!,</StyledParagraph>
        <StyledParagraph>Dziękujemy za złożenie zamówienia na bon upominkowy :)</StyledParagraph>
        <StyledHeading>Jak opłacić bon upominkowy?</StyledHeading>
        <StyledParagraph>
          Prosimy o dokonanie wpłaty należności: <br />
          Kwota bonu podarunkowego: {formatPriceToPln(price)} <br />
          na konto: 0000 0000 0000 0000 0000 00
          <br />W tytule przelewu proszę wpisać: &quot;{mail} za bon&quot;
        </StyledParagraph>
        <StyledHr />
        <StyledHeading>Jak i kiedy dotarczymy Twój bon upominkowy?</StyledHeading>
        <StyledParagraph>
          Bon upominkowy zostanie wysłana do Ciebie na podany adres e-mail, zaraz po zaksięgowaniu
          wpłaty
        </StyledParagraph>
        <StyledHr />
        <StyledFooter>
          Jeżeli nie złożyłaś/złożyłeś zamówienia na bon upominkowy proszę zignoruj tą wiadomość.
        </StyledFooter>
      </StyledContainer>
    </StyledBody>
  </Html>
);

OrderCardMailUserTemplate.propTypes = {
  mail: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

const StyledBody = styled(Body)`
  background-color: '#ffffff';
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
`;

const StyledContainer = styled(Container)`
  margin: '0 auto';
  padding: '20px 0 48px';
`;

const StyledLogo = styled(Img)`
  margin: '0 auto';
`;

const StyledParagraph = styled(Text)`
  font-size: '16px';
  line-height: '26px';
`;

const StyledHeading = styled(Heading)`
  font-size: '18px';
`;

const StyledHr = styled(Hr)`
  border-color: '#cccccc';
  margin: '20px 0';
`;

const StyledFooter = styled(Text)`
  color: '#8898aa';
  font-size: '12px';
`;
