import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import PropTypes from 'prop-types';
import { maxDeviceSize } from 'utils/DeviceSize';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import { colors } from 'utils/colors';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';

export const TrainingCard = ({ title, image, hashImage, cardPage, description }) => (
  <StyledTrainingCardContainer>
    <StyledContentContainer>
      <StyledParagraph as="h2">{title}</StyledParagraph>
      <StyledBlurImageContainer>
        <BlurImage src={image} hash={hashImage} bgColor={colors.primary} alt={title} />
      </StyledBlurImageContainer>
      <StyledButtonLinkContainer>
        <ButtonLink
          to={cardPage}
          state={{
            title,
            description,
            image,
            hashImage,
          }}
        >
          Zobacz szczegóły
        </ButtonLink>
      </StyledButtonLinkContainer>
    </StyledContentContainer>
  </StyledTrainingCardContainer>
);
TrainingCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  hashImage: PropTypes.string.isRequired,
  cardPage: PropTypes.string.isRequired,
  description: PropTypes.string,
};

TrainingCard.defaultProps = {
  description: '',
};

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

const StyledTrainingCardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  justify-content: space-between;

  @media ${maxDeviceSize.tablet} {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

const StyledContentContainer = styled.div`
  @media ${maxDeviceSize.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledBlurImageContainer = styled.div`
  display: flex;
  justify-content: center;
  @media ${maxDeviceSize.tablet} {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

const StyledButtonLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
