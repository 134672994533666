import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { AiFillStar } from 'react-icons/ai';
import { maxDeviceSize } from 'utils/DeviceSize';
import { v4 } from 'uuid';

export const ReviewBox = ({ author, stars, review, link }) => {
  const generateStars = (starsNumber) => {
    const result = [];
    for (let index = 0; index < starsNumber; index++) {
      result.push(<AiFillStar fill="#FFFF00" />);
    }

    return result;
  };
  return (
    <StyledReviewBoxWrapper>
      <StyledAutor>{author}</StyledAutor>
      <StyledStars>
        {generateStars(stars).map(() => (
          <AiFillStar fill="#FF8413" key={v4()} />
        ))}
      </StyledStars>
      <StyledReviewBoxDescription>{review}</StyledReviewBoxDescription>
      <StyledShowOpinontLink href={link} target="_blank">
        Zobacz całą opinię
      </StyledShowOpinontLink>
    </StyledReviewBoxWrapper>
  );
};
ReviewBox.propTypes = {
  author: PropTypes.string.isRequired,
  stars: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  link: PropTypes.string,
};

ReviewBox.defaultProps = {
  link: '',
};

const StyledReviewBoxWrapper = styled.div`
  background-color: ${colors.primary};
  border: 1px solid rgba(228, 183, 116, 0.74);
  border-radius: 100%;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  background: ${colors.lightBackground};
  padding: 6rem 4rem;
  margin-left: 2rem;
  margin-right: 2rem;
  height: 400px;
  box-shadow: -2px 0px 24px -9px rgba(0, 0, 0, 1);
  @media ${maxDeviceSize.tablet} {
    margin: 1rem;
    padding: 5rem 4rem;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const StyledReviewBoxDescription = styled.p`
  color: ${colors.lightColor};
  line-height: 1.5;
  padding-top: 0.5rem;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`;

const StyledAutor = styled.p`
  font-size: 1.5rem;
  color: ${colors.black};
`;

const StyledStars = styled.div`s`;

const StyledShowOpinontLink = styled.a`
  margin-top: 2rem;
  color: ${colors.black};
  text-decoration: none;
  &:hover {
    color: ${colors.ternary};
  }
`;
