import { Body, Container, Head, Hr, Html, Img, Preview, Text } from '@react-email/components';
import PropTypes from 'prop-types';
import * as React from 'react';
import { formatPriceToPln } from 'services/FormatterService';
import styled from 'styled-components';

export const OrderCardMailTemplate = ({ phoneNumber, mail, price, comment }) => (
  <Html>
    <Head />
    <Preview>Między nami kobietami</Preview>
    <StyledBody>
      <StyledContainer>
        <StyledLogo
          src="https://d375139ucebi94.cloudfront.net/region2/pl/109035/logo/4d044822a193410e883a4746af34b7-miedzy-nami-kobietami-salon-ko-logo-45ce82dc499049ce948ba6cbc3ad1a-booksy.jpeg"
          width="170"
          height="50"
          alt="Między-nami-kobietami"
        />
        <StyledParagraph>Cześć Angelika,</StyledParagraph>
        <StyledParagraph>
          Właśnie przyszło zamówienie na bon upominkowy od &quot;{mail}&quot; z numerem telefonu:
          &quot;
          {phoneNumber}&quot;
        </StyledParagraph>
        <StyledParagraph>Kwota bonu podarunkowego: {formatPriceToPln(price)}</StyledParagraph>
        {comment && <StyledParagraph>Przesłane uwagi: {comment}</StyledParagraph>}
        <StyledParagraph>
          Użytkownik otrzymał już maila z kwotą i numerem konta bankowego do zapłaty, pamiętaj, aby
          sprawdzić, czy wpłata przyszła w celu wysłania bonu upominkowego dla klienta :)
        </StyledParagraph>
        <StyledHr />
        <StyledFooter>Asy rękawów</StyledFooter>
      </StyledContainer>
    </StyledBody>
  </Html>
);

OrderCardMailTemplate.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  mail: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

const StyledBody = styled(Body)`
  background-color: '#ffffff';
  font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';
`;

const StyledContainer = styled(Container)`
  margin: '0 auto';
  padding: '20px 0 48px';
`;

const StyledLogo = styled(Img)`
  margin: '0 auto';
`;

const StyledParagraph = styled(Text)`
  font-size: '16px';
  line-height: '26px';
`;

const StyledHr = styled(Hr)`
  border-color: '#cccccc';
  margin: '20px 0';
`;

const StyledFooter = styled(Text)`
  color: '#8898aa';
  font-size: '12px';
`;
