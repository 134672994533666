import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { SocialLink } from 'components/atoms/SocialLink/SocialLink';
import React from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { BiLogoFacebookCircle, BiLogoInstagram, BiPhone } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';
import { ContactLink } from 'components/atoms/ContactLink/ContactLink';
import { colors } from 'utils/colors';

export const Footer = () => (
  <StyledFooterWrapper>
    <StyledDescriptionWrapper>
      <StyledDescriptionContainer>
        <StyledHeading>Zapraszam</StyledHeading>
        <Paragraph>Tomasza Golloba 16F, 85-791 Bydgoszcz</Paragraph>
      </StyledDescriptionContainer>
      <StyledDescriptionContainer>
        <StyledHeading>Umów się na wizytę</StyledHeading>
        <Paragraph>
          <ContactLink
            href={process.env.REACT_APP_NUMBER_LINK}
            alt="Numer telefonu"
            Icon={BiPhone}
            textAfter={process.env.REACT_APP_NUMBER}
            ariaLabel="Numer telefonu"
            alternativeColor
          />
        </Paragraph>
        <ContactLink
          href={process.env.REACT_APP_EMAIL_LINK}
          alt="Adres e-mail"
          Icon={CiMail}
          textAfter={process.env.REACT_APP_EMAIL}
          ariaLabel="Adres e-mail"
          alternativeColor
        />
      </StyledDescriptionContainer>
      <StyledDescriptionContainer>
        <StyledHeading>Sprawdź salon na:</StyledHeading>
        <SocialLink
          href={process.env.REACT_APP_FACEBOOK_LINK}
          alt="między nami kobietami facebook"
          Icon={BiLogoFacebookCircle}
          ariaLabel="Facebook"
          color="primary"
          alternativeColor
        />
        <SocialLink
          href={process.env.REACT_APP_INSTAGRAM_LINK}
          alt="między nami kobietami instagram"
          ariaLabel="Instagram"
          Icon={BiLogoInstagram}
          alternativeColor
        />
      </StyledDescriptionContainer>
    </StyledDescriptionWrapper>
    <StyledCopyrightContainer>
      <StyledCopySection>
        <p>Copyright c 2023. Wszeklie prawa zastrzeżone - Asy rękawów</p>
        <a
          href="https://www.flaticon.com/free-icons/makeup"
          title="makeup icons"
          style={{ color: colors.white }}
        >
          Makeup icons created by Smashicons - Flaticon
        </a>
      </StyledCopySection>
    </StyledCopyrightContainer>
  </StyledFooterWrapper>
);

const StyledHeading = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const StyledDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media ${maxDeviceSize.tablet} {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledDescriptionContainer = styled.div`
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledFooterWrapper = styled.div`
  background-color: #333335;
  color: white;
  height: fit-content;
  padding: 1rem 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 2rem calc((100vw - 1300px) / 2);

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledCopyrightContainer = styled.div`
  margin: 3% 5% 0 5%;
  display: flex;
  justify-content: space-between;
  border-top: #b5b8b8 1px solid;
  text-align: center;
  justify-content: center;
`;

const StyledCopySection = styled.div`
  margin-top: 1rem;
  color: #b5b8b8;
  text-align: center;
`;
