import React from 'react';
import MainLayout from 'templates/MainLayout';
import SectionLayout from 'templates/SectionLayout';
import { Heading } from 'components/atoms/Heading/Heading';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import { colors } from 'utils/colors';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import LogoImage from 'assets/images/miedzy-nami-kobietami-logo-image.png';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';

export const GiftCardWrapper = ({ children }) => (
  <MainLayout>
    <StyledSectionLayout>
      <Heading withBlob="true" as="h1">
        Zamów bon upominkowy
      </Heading>
      <StyledFormContainer>
        <StyledOrderSectionWrapper>
          {children}
          <StyledOrderDescribe>
            <Paragraph>Podaruj bliskiej Ci osobie chwilę relaksu!</Paragraph>
            <Paragraph>
              Zamów bon upominkowy wpisując kwotę, adresu e-mail i numer telefonu
            </Paragraph>
            <Paragraph>Otrzymasz na adres mailowy informację ze szczegółami zamówienia.</Paragraph>
          </StyledOrderDescribe>
        </StyledOrderSectionWrapper>
        <StyledLogo src={LogoImage} alt="logo między nami kobietami" />
      </StyledFormContainer>
      <ToastContainer />
    </StyledSectionLayout>
  </MainLayout>
);

GiftCardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
};

GiftCardWrapper.defaultProps = {
  children: null,
};

const StyledSectionLayout = styled(SectionLayout)`
  margin-bottom: 250px;
  @media ${maxDeviceSize.tablet} {
    margin-bottom: 420px;
  }
`;

const StyledFormContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: ${colors.secondary};
  -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  transition: all 0.3s ease-in-out;

  @media ${maxDeviceSize.tablet} {
    width: 80%;
    margin: auto;
  }
`;

const StyledOrderSectionWrapper = styled.div`
  display: flex;
  align-self: center;
  align-items: center;

  @media ${maxDeviceSize.tablet} {
    display: block;
  }
`;

const StyledLogo = styled.img`
  display: block;
  width: 10%;
  height: 10%;
  margin-left: 80rem;
  background-color: ${colors.primary};
  border-radius: 100%;

  @media ${maxDeviceSize.smallScreen} {
    margin-left: 47rem;
    width: 20%;
    height: 20%;
  }

  @media ${maxDeviceSize.verySmallScreen} {
    margin-left: 44rem;
    width: 25%;
    height: 25%;
  }

  @media ${maxDeviceSize.tablet} {
    margin-left: 3rem;
    margin-top: 1rem;
    width: 70%;
    height: 70%;
  }
`;

const StyledOrderDescribe = styled.div``;
