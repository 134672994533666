import PricingData from 'assets/pricingData.json';
import jsonToArrayDeserializer from 'serializers/jsonToArrayDeserializer';

const PricingDataProvider = (category) => {
  const pricingData = jsonToArrayDeserializer(PricingData);

  return pricingData.filter((treatment) => treatment.category === category);
};

export default PricingDataProvider;
