import imagesData from 'assets/imagesData.json';
import jsonToArrayDeserializer from 'serializers/jsonToArrayDeserializer';

const GalleryProvider = (main = false) => {
    const images = jsonToArrayDeserializer(imagesData);

    if (main === true) {
        return images.filter((image) => image.main === true);
    }
  
    return images;
  };

export default GalleryProvider;
