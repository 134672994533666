import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

export const Loading = ({ message }) => (
  <StyledLoadingWrapper>
    <ReactLoading />
    <Paragraph>{message}</Paragraph>
  </StyledLoadingWrapper>
);

Loading.propTypes = {
  message: PropTypes.string,
};

Loading.defaultProps = {
  message: '',
};

const StyledLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
