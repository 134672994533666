import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/DeviceSize';

export const ContactLink = ({ href, alt, ariaLabel, Icon, textAfter, alternativeColor }) => (
  <StyledContactLink
    href={href}
    alt={alt}
    aria-label={ariaLabel}
    alternativeColor={alternativeColor}
  >
    <Icon /> {textAfter && textAfter}
  </StyledContactLink>
);

ContactLink.propTypes = {
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  textAfter: PropTypes.string,
  alternativeColor: PropTypes.bool,
};

ContactLink.defaultProps = {
  textAfter: '',
  alternativeColor: false,
};

const StyledContactLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  color: ${({ alternativeColor }) => (alternativeColor === true ? colors.primary : colors.black)};

  margin-bottom: 7px;

  @media ${maxDeviceSize.tablet} {
    svg {
      width: 25px;
      height: 25px;
    }
  }

  :hover {
    color: ${colors.secondary};
  }
`;
