import React from 'react';
import styled from 'styled-components';
import OwnerImage from 'assets/images/miedzy-nami-kobietami-angelika-juzyszyn.jpeg';
import { maxDeviceSize } from 'utils/DeviceSize';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import SectionLayout from 'templates/SectionLayout';
import { ButtonLink } from 'components/atoms/ButtonLink/ButtonLink';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import { colors } from 'utils/colors';

const About = () => (
  <StyledSectionLayout>
    <StyledDescriptionWrapper>
      <StyledParagraphWrapper>
        <Paragraph>
          Zapraszamy do przytulnego salonu kosmetycznego w Bydgoszczy, który zadziała na wszystkie
          Twoje zmysły.
        </Paragraph>
        <Paragraph>Oddaj się w nasze ręce a na pewno nie pożałujesz.</Paragraph>
        <Paragraph>
          Podkreśl swoje naturalne piękno, słuchając spokojnych dźwięków natury. Zyskaj chwilę
          relaksu, wypij kawę bądź aromatyczną herbatę podczas zabiegu i zyskaj masę przydatnych
          wskazówek pielęgnacyjnych.
        </Paragraph>
        <Paragraph>
          Zadbamy o Ciebie w indywidualny sposób, zapewniając najwyższą jakość usług i przyjemną
          atmosferę. Odkryj nasz salon kosmetyczny i daj się porwać na magiczną podróż do piękna i
          odprężenia.
        </Paragraph>
        <StyledButtonLinkWrapper>
          <ButtonLink to="/o-nas">Sprawdź więcej</ButtonLink>
        </StyledButtonLinkWrapper>
      </StyledParagraphWrapper>
      <StyledBlurImageContainer>
        <BlurImage
          hash="LlMtdX%N_NMx?bRiIUkBozWBRjt7"
          src={OwnerImage}
          alt="miedzy-nami-kobietami-angelika-juzyszyn"
          width="250"
          height="250"
          bgColor={colors.primary}
          style={{ width: '250px', height: '250px' }}
        />
      </StyledBlurImageContainer>
    </StyledDescriptionWrapper>
  </StyledSectionLayout>
);

const StyledSectionLayout = styled(SectionLayout)`
  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
    padding: 2rem calc((100vw - 1300px) / 2);
  }
`;

const StyledDescriptionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledParagraphWrapper = styled.div`
  padding: 0 50px 0 0;
  font-size: 1.2rem;

  @media ${maxDeviceSize.tablet} {
    padding: 0 2rem;
  }

  @media ${maxDeviceSize.smallScreen} {
    font-size: 1rem;
  }
`;

const StyledButtonLinkWrapper = styled.div`
  padding: 15px 0 0 0;

  @media ${maxDeviceSize.tablet} {
    display: none;
  }
`;

const StyledBlurImageContainer = styled.div`
  margin: auto;
`;

export default About;
