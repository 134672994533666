import React, { useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import PropTypes from 'prop-types';
import { maxDeviceSize } from 'utils/DeviceSize';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import { colors } from 'utils/colors';
import { Button } from 'components/atoms/Button/Button';
import { TreatmentCard } from '../TreatmentCard/TreatmentCard';

export const Treatment = ({
  name,
  imageAfter,
  imageBefore,
  hashImageAfter,
  hashImageBefore,
  description,
  referentionNote,
}) => {
  const [openTreatmentCard, setOpenTreatmentCard] = useState(false);
  const truncateDesc = (desc, limit = 80) => `${desc.slice(0, limit)}...`;
  return (
    <>
      <StyledTreatmentContainer>
        <StyledDescriptionContainer>
          <Paragraph as="h3">{name}</Paragraph>
          <StyledDescription>{truncateDesc(description)}</StyledDescription>
          <Button onClick={() => setOpenTreatmentCard(true)} style={{ textAlign: 'center' }}>
            Zobacz szczegóły
          </Button>
        </StyledDescriptionContainer>
        <StyledBlurImageContainer>
          <BlurImage
            src={imageAfter}
            hash={hashImageAfter}
            bgColor={colors.primary}
            style={{
              border: '1px solid rgba(228, 183, 116, 0.74)',
              width: '150px',
              height: '150px',
            }}
            withoutPadding="true"
          />
        </StyledBlurImageContainer>
      </StyledTreatmentContainer>
      <TreatmentCard
        open={openTreatmentCard}
        name={name}
        imageAfter={imageAfter}
        imageBefore={imageBefore}
        hashImageAfter={hashImageAfter}
        hashImageBefore={hashImageBefore}
        description={description}
        referentionNote={referentionNote}
        onClose={() => setOpenTreatmentCard(false)}
      />
    </>
  );
};
Treatment.propTypes = {
  name: PropTypes.string.isRequired,
  imageAfter: PropTypes.string.isRequired,
  imageBefore: PropTypes.string,
  hashImageAfter: PropTypes.string.isRequired,
  hashImageBefore: PropTypes.string,
  description: PropTypes.string.isRequired,
  referentionNote: PropTypes.string,
};

Treatment.defaultProps = {
  imageBefore: '',
  hashImageBefore: '',
  referentionNote: '',
};

const StyledTreatmentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;

  &:hover {
    transform: translateY(-2px);
    opacity: 0.8;
  }

  @media ${maxDeviceSize.tablet} {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

const StyledDescription = styled(Paragraph)`
  width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`;

const StyledDescriptionContainer = styled.div`
  @media ${maxDeviceSize.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const StyledBlurImageContainer = styled.div`
  @media ${maxDeviceSize.tablet} {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
`;
