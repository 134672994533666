import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlurImage from 'components/molecules/BlurImage/BlurImage';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';

export const TreatmentAfterBeforeGallery = ({ photos }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 4800, min: 0 },
      items: 1,
    },
  };
  return (
    <StyledGalleryContainer responsive={responsive}>
      {photos
        .filter((photo) => photo.hash !== '')
        .map((imageData) => (
          <BlurImage
            key={imageData.hash}
            src={imageData.imageSrc}
            alt={imageData.description}
            hash={imageData.hash}
            width={isMobile ? '100' : '399'}
            height={isMobile ? '150' : '399'}
            bgColor={colors.primary}
          />
        ))}
    </StyledGalleryContainer>
  );
};

TreatmentAfterBeforeGallery.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string.isRequired,
      imageSrc: PropTypes.string.isRequired,
    }),
  ),
};

TreatmentAfterBeforeGallery.defaultProps = {
  photos: [],
};

const StyledGalleryContainer = styled(Carousel)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media ${maxDeviceSize.tablet} {
    grid-template-columns: 1fr;
  }
`;
