import styled from 'styled-components';
import { maxDeviceSize } from 'utils/DeviceSize';
import blob from 'assets/images/blobs/blob.svg';
import { colors } from 'utils/colors';

export const Heading = styled.h2`
  background: ${({ withBlob }) => (withBlob === 'true' ? `url(${blob}) bottom left` : 'none')};
  background-repeat: no-repeat;
  text-align: ${({ center }) => (center ? 'center' : 'start')};
  font-size: clamp(1.5rem, 5vw, 2rem);
  margin-bottom: ${({ marginBottom }) => (marginBottom === 'false' ? '0' : '3rem')};

  @media ${maxDeviceSize.tablet} {
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  color: ${({ secondary }) => (secondary === 'true' ? colors.white : colors.lightBlack)};
`;

export const SmallHeading = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  margin-bottom: 0.5rem;
`;
