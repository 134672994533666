export const categories = {
  lashesAndEyebrows: {
    name: 'lashes and eyebrows',
    label: 'Brwi i rzęsy',
  },
  faceNeck: {
    name: 'faceNeck',
    label: 'Zabiegi na twarz, szyję, dekolt',
  },
  makeup: {
    name: 'makeup',
    label: 'Makijaż',
  },
  makeupPermanent: {
    name: 'makeup permanent',
    label: 'Makijaż permanentny',
  },
};
