import React, { useState } from 'react';
import { maxDeviceSize } from 'utils/DeviceSize';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { useLocation } from 'react-router-dom';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { ButtonSubmit } from 'components/atoms/ButtonSubmit/ButtonSubmit';
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validatePhoneNumber } from 'services/InputValidator';
import { StacionaryTrainingContentWrapper } from 'components/organisms/StacionaryTrainingContenetWrapper/StacionaryTrainingContenetWrapper';
import { Loading } from 'components/atoms/Loading/Loading';

const StacionaryTrainingContentPage = () => {
  const location = useLocation();
  const { title, image, hashImage, description } = location.state;
  const [data, setData] = useState({
    phoneNumber: '',
  });
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleData = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validatePhoneNumber(data.phoneNumber) === false) {
      setError('Niepoprawny numer telefonu.');
      return;
    }
    setLoading(true);
    Axios.post(
      `${process.env.REACT_APP_SERVER_URL}/leave-for-contact?token=${process.env.REACT_APP_MNK_TOKEN}`,
      {
        phoneNumber: data.phoneNumber,
        trainingTitle: title,
      },
    )
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch(() => {
        toast.error('Zostawienie numeru telefonu z prośbą o kontakt nie powiodło się.', {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  if (loading) {
    return (
      <StacionaryTrainingContentWrapper
        title={title}
        image={image}
        hashImage={hashImage}
        description={description}
      >
        <Loading message="Dziękujemy za zostawienie numeru telefonu, właśnie wysyłamy wiadomość:)" />
      </StacionaryTrainingContentWrapper>
    );
  }

  if (sent) {
    toast.success('Zostawiono numeru telefonu z prośbą o kontakt.', {
      position: 'top-right',
      autoClose: 5000,
      theme: 'colored',
    });

    return (
      <StacionaryTrainingContentWrapper
        title={title}
        image={image}
        hashImage={hashImage}
        description={description}
      >
        <Paragraph>Dziękujemy za zostawienie numeru telefonu :)</Paragraph>
      </StacionaryTrainingContentWrapper>
    );
  }
  return (
    <StacionaryTrainingContentWrapper
      title={title}
      image={image}
      hashImage={hashImage}
      description={description}
    >
      <StyledOrderForm onSubmit={(e) => handleSubmit(e)}>
        <label htmlFor="phoneNumber"> Numer telefonu: </label>
        <StyledOrderInput
          type="text"
          id="phoneNumber"
          required
          name="phoneNumber"
          placeholder="Numer telefonu"
          onChange={(e) => handleData(e)}
          onEnded={() => validatePhoneNumber(data.phoneNumber)}
          value={data.phoneNumber}
        />
        {error && <StyledErrorContainer>Niepoprawny numer telefonu</StyledErrorContainer>}
        <StyledOrderSubmitButton type="submit">Wyślij</StyledOrderSubmitButton>
      </StyledOrderForm>
    </StacionaryTrainingContentWrapper>
  );
};

const StyledOrderForm = styled.form`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media ${maxDeviceSize.tablet} {
    padding: 1rem 0 !important;
  }
`;

const StyledOrderInput = styled.input`
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;

  &:focus {
    outline: none !important;
    border: 2px solid ${colors.secondary};
    box-shadow: 0 0 10px #719ece;
  }
`;

const StyledOrderSubmitButton = styled(ButtonSubmit)`
  padding: 12px 20px;
  margin: 8px 0;
  background-color: ${colors.lightBlack};
  outline: none !important;
  border: 1px solid #ccc;
  color: white;
  font-size: 1rem;
  text-align: center;

  &:hover {
    color: ${colors.ternary};
  }
`;

const StyledErrorContainer = styled.div`
  color: white;
  font-size: 0.8rem;
  background-color: rgba(255, 0, 0, 0.8);
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid red;
`;

export default StacionaryTrainingContentPage;
